<template>
  <app-module-view>
    <template slot="body">
      <app-subscription-order-filter></app-subscription-order-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="orders"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import SubscriptionOrderFilter from './SubscriptionOrderFilter'
import DataTable from '../shared/DataTable'
import PaymentTypeMixin from '../mixins/valueObject/eshop/PaymentTypeMixin'
import PaymentResultTypeMixin from '../mixins/valueObject/eshop/PaymentResultTypeMixin'
import StoreService from '../../services/eshop/StoreService'

export default {
  name: 'SubscriptionOrderList',
  mixins: [PaymentTypeMixin, PaymentResultTypeMixin],
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('eshopSubscriptionOrder.id'),
          variableSymbol: this.$t('eshopSubscriptionOrder.variableSymbol'),
          product: this.$t('eshopSubscriptionOrder.product'),
          payment: this.$t('eshopSubscriptionOrder.payment'),
          paymentResult: this.$t('eshopSubscriptionOrder.paymentResult'),
          email: this.$t('eshopSubscriptionOrder.email'),
          priceInEuro: this.$t('eshopSubscriptionOrder.price'),
          'address.name': this.$t('eshopSubscriptionOrder.address.name'),
          'address.surname': this.$t('eshopSubscriptionOrder.address.surname'),
          'address.company': this.$t('eshopSubscriptionOrder.address.company'),
          createdAt: this.$t('eshopSubscriptionOrder.created_at')
        },
        actions: {
          detail: 'eshopSubscriptionOrder_detail'
        },
        render: {
          product: function (data) {
            return this.products.find(product => {
              if (data === product.id) {
                return product
              }
            }).name
          }.bind(this),
          email: function (data) {
            if (parseInt(data) > 0) {
              return '<a target="_blank" href="/feUser/' + this.getUserIdByEmail(data) + '/edit">' + data + '</a>'
            }
            return data
          }.bind(this),
          payment: function (data) {
            return this.paymentTypeById(data).title
          }.bind(this),
          paymentResult: function (data) {
            return this.paymentResultTypeById(data).title
          }.bind(this)
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    orders () {
      return this.$store.getters['eshopSubscriptionOrder/list']
    },
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    },
    totalCount () {
      return this.$store.getters['eshopSubscriptionOrder/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['eshopSubscriptionOrder/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appSubscriptionOrderFilter: SubscriptionOrderFilter,
    appDataTable: DataTable
  },
  methods: {
    getOrders () {
      this.$store.dispatch('eshopSubscriptionOrder/fetch')
    },
    getUserIdByEmail (email) {
      return this.orders.find(order => {
        if (email === order.email) {
          return order
        }
      }).feUser
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('eshopSubscriptionOrder/setPage', page)
      this.getOrders()
    }
  },
  mounted () {
    this.page = this.$store.getters['eshopSubscriptionOrder/page']
    this.getOrders()
    StoreService.fetchAll()
  }
}
</script>

<style lang="scss">

.table-responsive {
  td:nth-child(2) {
    min-width: 200px;
  }
}

</style>
